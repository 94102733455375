import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import IllustrationAndPrimaryBackgroundHero from "components/hero/TwoColumnWithPrimaryBackground.js";
import GetStarted from "components/cta/GetStarted";

const Container = tw.div`bg-primary-900 -mx-8 -my-8`;

export default () => {

  const HighlightedText = tw.span`text-primary-300`;

  return (
    <AnimationRevealPage>
      <Container>
        <IllustrationAndPrimaryBackgroundHero
          heading = {<>WEBSITE UNDER <HighlightedText>CONSTRUCTION</HighlightedText></>}
          description = {"Adding Nurture to Nutraceuticals. We provide a wide range of cost effective high quality nutraceuticals for consumers around the globe."}
          primaryButtonText = {"See our first product available!"}
          primaryButtonUrl = {"/PotassiumIodide"}
        />
        <GetStarted/>
      </Container>
    </AnimationRevealPage>
  );
}
