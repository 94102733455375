import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import PotassiumIodide from "demos/PotassiumIodide.js"
import PotassiumIodide130mg from "demos/PotassiumIodide130mg.js"
import ComingSoon from "demos/ComingSoon.js"

export const components = {
    PotassiumIodide130mg: {
      component: PotassiumIodide130mg,
      url: "/PotassiumIodide130mg",
    },
    PotassiumIodide: {
      component: PotassiumIodide,
      url: "/PotassiumIodide",
    },    
    ComingSoon: {
      component: ComingSoon,
      url: "/ComingSoon",
    },
}

export default () => {
  const { name } = useParams()

  try {
    let Component = null;
    Component= components[name].component

    if(Component)
      return <Component/>

    throw new Error("Component Not Found")
  }
  catch (e) {
    console.log(e)
    return <Navigate to="/ComingSoon" />
  }
}
