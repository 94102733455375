import React, { useState } from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { Container } from "components/misc/Layouts";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm"
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as CartIcon } from "feather-icons/dist/icons/shopping-cart.svg";
import DesignIllustration from "../../images/MHH-PotassiumIodide65mg60tablets.png";

const PrimaryBackgroundContainer = tw.div`py-10 lg:py-12 bg-primary-500 rounded-lg relative mt-10`
const Row = tw.div`px-8 max-w-screen-xl mx-auto flex items-center relative z-10 flex-col-reverse lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(ColumnContainer)`flex justify-center my-6 lg:mt-0 flex-col `;

const Link = tw.a`max-w-sm w-full text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 lg:mx-8 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline text-center`;
const PrimaryLink = tw(Link)`flex bg-orange-amazon text-gray-100 shadow-lg justify-center hocus:bg-red-600 hocus:text-gray-200`;
const SecondaryLink = tw(Link)`text-gray-100 border-gray-500 hover:bg-gray-100 hover:text-primary-500 hover:border-primary-500`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  text = "Protect your thyroid with potassium iodide - order now and be prepared for the unexpected!",
  primaryLinkText = " Buy on Amazon",
  primaryLinkUrl = "https://amzn.to/3W6o30F",
  secondaryLinkText = "Contact Us",
  pushDownFooter = false,
  imageSrc = DesignIllustration
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <PrimaryBackgroundContainer>
        <Row>
          <TextContainer>
            <Text>{text}</Text>
          </TextContainer>
          <LinksContainer>
            <PrimaryLink href={primaryLinkUrl}><CartIcon tw="mr-3"/> {primaryLinkText}</PrimaryLink>
            <SecondaryLink onClick={toggleModal}>{secondaryLinkText}</SecondaryLink>
          </LinksContainer>
          <img tw="min-w-0 w-1/3 max-w-sm z-20 xl:pr-12 -mt-24 lg:-mt-24 mx-12" src={imageSrc} alt="Potassium Iodide" />
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer>
      </PrimaryBackgroundContainer>
      <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <Form/>
          </div>
        </StyledModal>
    </Container>
  );
};
