import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/FourColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithFourFeaturesAndButtons.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlansBulk.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SimpleWithSideImage";
import GetStarted from "components/cta/GetStarted";
import productImg from "images/MHH-PotassiumIodide65mg60tablets.png";
import macHeroScreenshotImageSrc from "images/Lifestyle2.png";
import prototypeIllustrationImageSrc from "images/Lifestyle4.png";
import testimonialPicture from "images/Lifestyle5.png";
import faqPicture from "images/Lifestyle1.png";
import threePack from "images/3190(3-PACK).png";
import sixPack from "images/3190(6-PACK).png";
import tenPack from "images/3190(10-PACK).png";
import { ReactComponent as isoIcon } from "images/iso.svg";
import { ReactComponent as coeurIcon } from "images/coeur.svg";
import { ReactComponent as sceauIcon } from "images/sceau.svg";
import { ReactComponent as feuillesIcon } from "images/feuilles.svg";
import profile from "images/no-profile-picture-icon.svg";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>

      <Hero 
        title={<>Protect yourself and your family from <HighlightedText>radiation exposure</HighlightedText> with our Potassium Iodide supplements.</>}
        subTitle={<>Our high-quality, easy-to-swallow tablets can help loosen and break up mucus in the airways.* This could help you cough up mucus so you can breathe more easily if you have long-term lung problems (such as asthma, chronic bronchitis, emphysema). <br /> <HighlightedText>For short-term use only</HighlightedText>.</>}
        roundedHeaderButton={true} 
        imageSrc={productImg} 
        href="https://www.amazon.com/Mothers-Healthy-Healing-Potassium-Supplement/dp/B0BMDK3HLC?crid=1PMU0TU0ELO42&keywords=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide&qid=1671821473&sprefix=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide%2Caps%2C147&sr=8-4&th=1&linkCode=ll1&tag=newrootsherba-20&linkId=5fbed2bddac45817d0f161c0aaefbe56&language=en_US&ref_=as_li_ss_tl"
      />

      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      />

      <FeatureWithSteps
        subheading={<Subheading>Short-Term use only</Subheading>}
        heading={
          <>
            Key <HighlightedText>Benefits</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={productImg}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps = {[
          {
            heading: "Healthy thyroid",
            description: "Supports healthy thyroid function and prevents iodine deficiency."
          },
          {
            heading: "Protect from radiation",
            description: "Can protect the thyroid gland from radiation in emergency situations."
          },
          {
            heading: "Nutritional",
            description: "Used as a dietary supplement to boost iodine levels."
          },
          {
            heading: "Detoxification",
            description: "Saturating the thyroid with iodine can prompt the release of undesirable halogens from the thyroid."
          }
        ]}
        href="https://www.amazon.com/Mothers-Healthy-Healing-Potassium-Supplement/dp/B0BMDK3HLC?crid=1PMU0TU0ELO42&keywords=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide&qid=1671821473&sprefix=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide%2Caps%2C147&sr=8-4&th=1&linkCode=ll1&tag=newrootsherba-20&linkId=5fbed2bddac45817d0f161c0aaefbe56&language=en_US&ref_=as_li_ss_tl"
      />
      
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            100% Natural & <HighlightedText>Safe.</HighlightedText>
          </>
        }
        imageSrc={productImg}
        showDecoratorBlob={true}
        features={[
          {
            Icon: isoIcon,
            title: "ISO 17025-Accredited",
            description: "All raw materials are tested three times over at our state-of-the-art 8000 sq. ft. laboratory.",
            iconContainerCss: tw`bg-secondary-100/0`
          },
          {
            Icon: feuillesIcon,
            title: "Naturally Sourced",
            description: "Our products are made with naturally sourced ingredients that are potency-validated",
            iconContainerCss: tw`bg-secondary-100/0`
          },
          {
            Icon: coeurIcon,
            title: "Family-Owned and -Operated",
            description: "We are a North American-owned, familly-run business.",
            iconContainerCss: tw`bg-secondary-100/0`
          },
          {
            Icon: sceauIcon,
            title: "Rooted in Excellence",
            description: "Mothers Healthy healing is manufactured in a GMP compliant & Health Canada approved, best-in-class production facility.",
            iconContainerCss: tw`bg-secondary-100/0`
          }
        ]}
        href="https://www.amazon.com/Mothers-Healthy-Healing-Potassium-Supplement/dp/B0BMDK3HLC?crid=1PMU0TU0ELO42&keywords=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide&qid=1671821473&sprefix=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide%2Caps%2C147&sr=8-4&th=1&linkCode=ll1&tag=newrootsherba-20&linkId=5fbed2bddac45817d0f161c0aaefbe56&language=en_US&ref_=as_li_ss_tl"
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Bulk up before <HighlightedText>we run out.</HighlightedText>
          </>
        }
        description={<>We also sell different concentrations:</>}
        variations={[
          {
            name: "65 mg",
            href: "/PotassiumIodide",
            selected: true
          },
          {
            name: "130 mg",
            href: "/PotassiumIodide130mg"
          }
        ]}
        plans={[
          {
            name: "3 pack bundle",
            price: "$32.40",
            mainFeature: "For Individuals",
            planImageSrc: threePack,
            href: "https://amzn.to/3X4UJbd"
          },
          {
            name: "6 pack bundle",
            price: "$57.60",
            mainFeature: "For Small Family",
            planImageSrc: sixPack,            
            featured: true,
            href: "https://amzn.to/3QfYitg"
          },
          {
            name: "10 pack bundle",
            price: "$84.00",
            mainFeature: "For Large Family",
            planImageSrc: tenPack,
            href: "https://amzn.to/3CkBOBj"
          }
        ]}
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        imageSrc={productImg}
        textOnLeft={true}
        heading={
          <>
            Why Choose Our <HighlightedText>Potassium Iodide</HighlightedText> Supplements?
          </>
        }
        description={<>Read what our customers have to say about the effectiveness of our product and its positive impact on their health.</>}
        testimonials={[
          {
            stars: 5,
            profileImageSrc: profile,
            heading: "Exactly as described. Confirmed - bought 2022, expires 2027",
            quote:
              "Hope I never need them but good to have in my emergency bag. Sadly, its crazy times. Advertised expiration date confirmed. 👍",
            customerName: "Jessica Thompson",
          },
          {
            stars: 5,
            profileImageSrc: profile,
            heading: "Just as advertised!!!",
            quote:
              "So glad I was able to find these & at a great price!!! \n The product came quickly, as advertised. \n Thanks again.",
            customerName: "	David Williams",
          },
          {
            stars: 5,
            profileImageSrc: profile,
            heading: "Peace of Mind!",
            quote:
              "Great packaging and received quickly. I hope I never have to use these but I trust this brand and it’s ingredients, so this is great to have on hand. I don’t think it’s a bad idea to stock up on these just in case…",
            customerName: "Emily Johnson",
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        imageSrc={productImg}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is potassium iodide and how does it work?",
            answer:
              "Potassium iodide is a chemical compound that is made up of potassium and iodine. It is used as a dietary supplement to help support healthy thyroid function and protect the thyroid gland from damage."
          },
          {
            question: "Is it safe to take potassium iodide supplements?",
            answer:
              "Potassium iodide supplements are generally considered safe when taken as directed. However, as with any supplement, it is important to talk to your doctor before starting a new supplement regimen, especially if you have any pre-existing health conditions or are taking any medications."
          },
          {
            question: "Can potassium iodide supplements help with thyroid problems? ",
            answer:
              " Potassium iodide supplements can help support healthy thyroid function and protect the thyroid gland from damage. However, they should not be used as a replacement for medical treatment for thyroid problems. It is important to talk to your doctor if you are experiencing any symptoms of a thyroid disorder."
          },
          {
            question: "Are there any side effects of taking potassium iodide supplements?",
            answer:
              "Potassium iodide supplements are generally well-tolerated, but some people may experience side effects such as stomach upset, nausea, or skin rash. If you experience any side effects while taking potassium iodide supplements, it is important to talk to your doctor."
          },
          {
            question: "Who should not take potassium iodide supplements?",
            answer:
              "Potassium iodide supplements are not recommended for people who are allergic to iodine or have certain medical conditions such as hyperkalemia (high levels of potassium in the blood) or certain types of thyroid cancer. It is important to talk to your doctor before starting a potassium iodide supplement regimen."
          }
        ]}
      />
      <GetStarted imageSrc={productImg}
      primaryLinkUrl="https://www.amazon.com/Mothers-Healthy-Healing-Potassium-Supplement/dp/B0BMDK3HLC?crid=1PMU0TU0ELO42&keywords=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide&qid=1671821473&sprefix=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide%2Caps%2C147&sr=8-4&th=1&linkCode=ll1&tag=newrootsherba-20&linkId=5fbed2bddac45817d0f161c0aaefbe56&language=en_US&ref_=as_li_ss_tl"/>
    </AnimationRevealPage>
  );
}
