import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/MHH-PotassiumIodide65mg60tablets.png";
import { ReactComponent as CartIcon } from "feather-icons/dist/icons/shopping-cart.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto pt-20 md:pt-24`;
const LeftColumn = tw.div`relative lg:w-7/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-gray-700`;

const Actions = styled.div`
  ${tw`relative max-w-sm text-center mx-auto md:mr-0`}
  button {
    ${tw`w-full bg-orange-amazon text-gray-50 font-bold rounded-full py-4 px-4 flex items-center justify-center sm:leading-none focus:outline-none hover:bg-orange-500 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default ({ roundedHeaderButton,
  imageSrc = DesignIllustration,
  title = "Product Title",
  subTitle = "Product subTitle",
  href = "https://www.amazon.com/Mothers-Healthy-Healing-Potassium-Supplement/dp/B0BMDK3HLC?crid=1PMU0TU0ELO42&keywords=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide&qid=1671821473&sprefix=mothers%2Bhealthy%2Bhealing%2Bpotassium%2Biodide%2Caps%2C147&sr=8-4&th=1&linkCode=ll1&tag=newrootsherba-20&linkId=5fbed2bddac45817d0f161c0aaefbe56&language=en_US&ref_=as_li_ss_tl",
}) => {
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              {title}
            </Heading>
            <Paragraph>
              {subTitle}
            </Paragraph>
            <Actions>
              <a href={href}><button><CartIcon tw="mr-3"/> Order Now on Amazon</button></a>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" tw="absolute bottom-0 right-0 ">
              <path fill="#5e4f36" d="M21.7,-24.4C35.7,-24.8,59.8,-31.6,73.2,-26.4C86.6,-21.2,89.3,-4,86.1,11.8C82.9,27.6,74,42,62.4,52.4C50.8,62.8,36.5,69.2,22.8,69.5C9.1,69.7,-3.9,63.8,-17.2,59.5C-30.5,55.2,-43.9,52.5,-48.5,43.9C-53.1,35.3,-48.7,20.8,-50.5,7.4C-52.2,-6.1,-60.1,-18.6,-58.4,-28.5C-56.7,-38.5,-45.6,-46,-34.2,-46.9C-22.9,-47.8,-11.5,-42.1,-3.8,-36.2C3.9,-30.3,7.7,-24.1,21.7,-24.4Z" transform="translate(100 100)" />
            </svg>
            <IllustrationContainer>
              <img tw="min-w-0 w-1/2 max-w-md xl:max-w-md z-10 xl:pr-12" src={imageSrc} alt="Potassium Iodide" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
